

































import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'SelectArea',
  props: {
    value: {
      type: [Array, String],
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'mdi-chevron-down',
    },
  },
  data () {
    return {
      area: this.value,
    }
  },
  watch: {
    value: {
      handler () {
        (this.area as unknown) = this.value
      },
    },
  },
  methods: {
    onInput (value: string) {
      this.$emit('input', value)
    },
    onChange (value: string) {
      this.$emit('change', value)
    },
  },
})
